import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const input = document.querySelector('.js-input-plan-id');
    const plans = [].slice.call(document.querySelectorAll('.js-plan-item') || []);

    if (input) {
      const planSelected = function() {
        plans.map(plan => plan.classList.remove('-selected'));
        this.classList.add('-selected');
        return input.value = this.getAttribute('data-plan-id');
      };

      plans.map(plan => plan.addEventListener('click', planSelected));
    }
  }
}
