<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações do Cliente
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field required">
          <label class="label">
            <abbr>*</abbr>
            Selecione seu cliente
          </label>

          <CoolSelect
            v-model="selected"
            item-text="name"
            :items="resources"
            placeholder="Selecione um cliente"
            @select="onSelect"
          >
            <template slot="no-data">
              Não foi encontrado nenhum resultado.
            </template>

            <template
              slot="item"
              slot-scope="{ item }"
            >
              <span> {{ item.name }} <strong>({{ item.cpf_cnpj | formatCpfCnpj }})</strong></span>
            </template>
          </CoolSelect>
        </div>
      </div>

      <div
        class="column is-narrow"
        style="align-self:flex-end;"
      >
        <a
          class="button is-medium"
          @click.prevent="openModal()"
        >
          + Novo Cliente
        </a>
      </div>
    </div>

    <Modal
      v-if="isVisible"
      title="Novo cliente"
      :is-visible="isVisible"
      :close-modal="closeModal"
      :save-button="handleSubmit"
    >
      <NewCustomer ref="NewCustomer" />
    </Modal>
  </div>
</template>

<script>
import Modal from 'app/v1/src/components/Modal';
import NewCustomer from 'app/v1/src/Customer/New';
import { CoolSelect } from 'vue-cool-select';
import { formatCpfCnpj } from 'app/v1/src/utils/filters';

export default {
  filters: {
    formatCpfCnpj
  },

  components: {
    Modal,
    NewCustomer,
    CoolSelect
  },

  props: {
    resources: {
      type: Array,
      required: true
    },

    onSelect: {
      type: Function,
      required: true
    },

    addResource: {
      type: Function,
      required: true
    },
  },

  data() {
    return {
      isVisible: false,
      selected: null,
    };
  },

  methods: {
    handleSubmit() {
      this.$refs.NewCustomer.handleSubmit()
        .then(data => {
          this.addResource(data);
          this.closeModal();
        })
        .catch(() => Promise.reject('FAIL_ADD_CUSTOMER'));
    },

    openModal() {
      this.isVisible = true;
    },

    closeModal() {
      this.isVisible = false;
    },
  }
};
</script>
