import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const taxpayer_type  = $('.js-taxpayer-type');

    const change_values_by_type = function(value) {
      if (value === 'free') {
        $('.js-state-registration').val('');
        return $('.js-state-registration').attr('disabled', true);
      } else {
        return $('.js-state-registration').attr('disabled', false);
      }
    };

    change_values_by_type(taxpayer_type.val());

    taxpayer_type.change(function() {
      return change_values_by_type($(this).val());
    });
  }
}
