<template>
  <div>
    <Buyer
      :resources="customers"
      :on-select="selectBuyer"
      :add-resource="addBuyer"
    />

    <ProductForm
      :nfe="payment_paper"
    />

    <PaymentForm
      :resource="payment_paper"
      :validator="$v"
    />

    <AdditionalForm
      :resource="payment_paper"
      :validator="$v"
    />

    <div class="control-button">
      <button
        type="submit"
        class="button is-primary is-large"
        @click="handleSubmit"
      >
        Criar Carnê
      </button>
    </div>
  </div>
</template>

<script>
import Buyer from './src/buyer';
import AdditionalForm from './src/additional-form';
import PaymentForm from './src/payment-form';
import ProductForm from 'app/v1/src/ProductListCart/ProductListCart';
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import { isEmpty } from 'lodash';
import { required, minLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { addDays, toDate } from 'app/v1/src/utils/filters';

export default {
  components: {
    Buyer,
    ProductForm,
    AdditionalForm,
    PaymentForm
  },

  mixins: [
    validationMixin
  ],

  data() {
    return {
      customers: [],
      payment_paper: {
        buyer_id: '',
        buyer_type: 'Customer',
        description: '',
        items: [],
        installment_numbers: 1,
        installments_attributes: {}
      },
    };
  },

  validations() {
    return {
      payment_paper: {
        buyer_id: { required },
        buyer_type: { required },
        installments_attributes: {
          required,
          minLength: minLength(1),
          $each: {
            installment_number: { required },
            installment_value: { required },
            installment_date: { required },
          }
        },
        items: {
          required,
          minLength: minLength(1),
          $each: {
            product_id: { required },
            amount: { required },
            unit: { required },
            cfop: { required },
            price: { required },
          }
        }
      }
    };
  },

  computed: {
    total: function() {
      return this.payment_paper.items.reduce((prev, item) => {
        return prev + (parseFloat(item.price) * parseFloat(item.amount));
      }, 0);
    },
  },

  watch: {
    'payment_paper.installment_numbers': {
      handler: function() {
        this.setInstallment();
      }
    },

    'payment_paper.items': {
      handler: function() {
        this.setInstallment();
      },
      deep: true
    },
  },

  mounted() {
    this.setBuyers();
  },

  methods: {
    handleSubmit(e) {
      const lastItem = this.payment_paper.items.slice(-1)[0];

      if(this.payment_paper.items.length > 1 && isEmpty(lastItem.selected)) {
        this.payment_paper.items.pop();
      }

      if (this.$v.payment_paper.$invalid) {
        console.log('Invalid....');
        this.$v.payment_paper.$touch();
        e.preventDefault();
      } else {
        this.handleCreate();
      }
    },

    handleCreate() {
      console.log('handleCreate....');
      const { payment_paper } = this;
      http.post('/app/payment_papers', { payment_paper })
        .then(getData)
        .then(() => Turbolinks.visit('/app/payment_papers'))
        .catch(() => Promise.reject('FAIL_IN_CREATE'));
    },

    selectBuyer(buyer) {
      this.payment_paper.buyer_id = buyer.id;
    },

    addBuyer(buyer) {
      this.customers = [...this.customers, buyer];
    },

    setBuyers() {
      http.get('/api/customers')
        .then(getData)
        .then(data => this.customers = data)
        .catch(() => Promise.reject('FAIL_IN_FETCH_CUSTOMERS'));
    },

    setInstallment() {
      const installmentNumbers = this.payment_paper.installment_numbers;
      const newInstallments = Array(parseInt(installmentNumbers)).fill().map((_, i) => {
        const installmentNumber = i + 1;
        const installmentValue = this.total / installmentNumbers;
        return {
          installment_number: installmentNumber,
          installment_value: installmentValue,
          installment_date: toDate(addDays(installmentNumber * 30)),
          key: Math.round(+new Date() * Math.random())
        };
      });

      this.payment_paper.installments_attributes = newInstallments;
    }
  }
};
</script>

<style scoped>
</style>
