import { Controller } from 'stimulus';
import Flatpickr from 'flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';

export default class extends Controller {
  static targets = [ 'date' ]

  connect() {
    this.flatPickr = new Flatpickr(this.dateTarget, {
      altInput: true,
      altFormat: 'd/m/Y',
      dateFormat: 'Y-m-d',
      defaultDate: this.defaultDate(),
      locale: Portuguese
    });
  }

  disconnect() {
    Turbolinks.clearCache();
    this.flatPickr.destroy();
  }

  defaultDate() {
    return this.dateTarget.value;
  }
}
