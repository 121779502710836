<template lang="html">
  <input
    ref="input"
    type="string"
    :value="valueFormmatted"
    :disabled="disabled"
    :class="classes"
    @change="updateValue($event.target.value)"
  >
</template>

<script>
import { toNumberFormatted, toNumber } from 'app/v1/src/utils/filters';

export default {
  props: ['value', 'disabled', 'classes'],

  computed: {
    valueFormmatted: function() {
      return toNumberFormatted(this.value, '0.000');
    }
  },

  methods: {
    updateValue(newValue) {
      this.$emit('input', toNumber(newValue));
    }
  }
};
</script>
