<template>
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações de Pagamentos
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Número de Prestações</label>
          </div>

          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <input
                  v-model="resource.installment_numbers"
                  type="number"
                  min="1"
                  class="input"
                >
              </div>
            </div>
          </div>
        </div>

        <table class="table is-bordered">
          <thead>
            <tr>
              <th>Parcela</th>
              <th>Valor</th>
              <th>Vencimento</th>
            </tr>
          </thead>

          <tbody>
            <PaymentItem
              v-for="installment in resource.installments_attributes"
              :key="installment.id || installment.key"
              :installment="installment"
              :installment-numbers="parseInt(resource.installment_numbers)"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { toCurrency } from 'app/v1/src/utils/filters';
import PaymentItem from './payment-item';

export default {
  filters: {
    toCurrency
  },

  components: {
    PaymentItem
  },

  props: {
    resource: {
      type: Object,
      required: true
    },

    validator: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
  .field-label.is-normal {
    margin-right: 0;
    flex-grow: 0.8;
    text-align: left;
  }
</style>
