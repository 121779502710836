import { Controller } from 'stimulus';
import Handlebars from 'handlebars';
import Moment from 'moment';

export default class extends Controller {
  connect() {
    Handlebars.registerHelper('checkStatus', function(status) {
      switch (status) {
      case 'pending':    return 'Pendente';
      case 'canceled':   return 'Cancelado';
      case 'paid':       return 'Pago';
      case 'refunded':   return 'Reembolsado';
      default: return 'Indefinido';
      }
    });

    Handlebars.registerHelper('FormatterToDate', date => Moment(date).format('DD/MM/YYYY'));
    Handlebars.registerHelper('LowerCase', value => value.toLowerCase());
  }
}
