import { Controller } from 'stimulus';
import Vue from 'vue/dist/vue.esm';
import App from 'app/v1/src/installment/list.vue';
import i18n from 'app/v1/locales';

export default class extends Controller {
  static targets = [ 'content' ]

  showList(event) {
    event.stopPropagation();
    const el = this.contentTarget;
    const paymentPaperId = event.currentTarget.dataset.paymentPaperId;
    new Vue({
      el,
      i18n,
      render: h => h(App, {
        props: {
          paymentPaperId: paymentPaperId
        }
      })
    });
  }
}
