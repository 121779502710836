import Vue from 'vue/dist/vue.esm';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  'pt-BR': {
    paymentPaper: {
      unpaid: 'Não Pago',
      paid: 'Pago'
    }
  }
};

const i18n = new VueI18n({
  locale: 'pt-BR',
  messages,
});

export default i18n;
