<template>
  <div data-target="installment--list.content">
    <Modal
      title="Parcelamentos"
      :is-visible="isVisible"
      :show-footer="false"
      :close-modal="closeModal"
    >
      <table class="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>Parcela</th>
            <th>Vence em</th>
            <th>Valor</th>
            <th>Status</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th />
            <th />
            <th />
            <th />
            <th />
          </tr>
        </tfoot>
        <tbody>
          <Installment
            v-for="installment in installments"
            :key="installment.id"
            :installment="installment"
            :update-status="updateStatus"
          />
        </tbody>
      </table>
    </Modal>
  </div>
</template>

<script>
import Modal from 'app/v1/src/components/Modal';
import Installment from './src/item';
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';

export default {
  components: {
    Modal,
    Installment
  },

  props: {
    paymentPaperId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isVisible: true,
      installments: []
    };
  },

  mounted() {
    this.setInstallments();
  },

  methods: {
    setInstallments() {
      http.get(`/app/payment_papers/${this.paymentPaperId}/installments`)
        .then(getData)
        .then(data => this.installments = data)
        .catch(() => Promise.reject('FAIL_IN_FETCH'));
    },

    updateStatus(id, status) {
      http.patch(`/app/payment_papers/${this.paymentPaperId}/installments/${id}`, status)
        .then(getData)
        .then(data => {
          const installment = this.installments.find(installment => installment.id === data.id);
          installment.status = data.status;
        })
        .catch(() => console.warn('FAIL_IN_UPDATE'));
    },

    closeModal() {
      this.isVisible = false;
    },
  }
};
</script>

<style scoped>
</style>
