<template lang="html">
  <input
    ref="input"
    v-mask="'##/##/####'"
    type="tel"
    placeholder="DD/MM/YYYY"
    :value="valueFormmatted"
    :disabled="disabled"
    :class="classes"
    @change="updateValue($event.target.value)"
  >
</template>

<script>
import { toDate, formatDate } from 'app/v1/src/utils/filters';
import { mask } from 'vue-the-mask';

export default {
  directives: {
    mask
  },

  props: {
    value: {
      type: String,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classes: {
      type: String,
      default: 'input'
    }
  },

  computed: {
    valueFormmatted: function() {
      return formatDate(this.value);
    }
  },

  methods: {
    updateValue(value) {
      const normalValue = toDate(value);
      this.$emit('input', normalValue);
    }
  }
};
</script>
