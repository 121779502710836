<template lang="html">
  <form class="customer-form">
    <div class="field">
      <label
        class="label"
        for="customer_person"
      >
        <abbr title="necessário">*</abbr>
        Tipo
      </label>

      <div class="control">
        <span class="select is-medium is-fullwidth">
          <select
            id="customer_person"
            v-model="customer.person"
          >
            <option value="legal_entity">Pessoa Jurídica</option>
            <option value="individual">Pessoa Física</option>
          </select>
        </span>
      </div>
    </div>

    <div
      class="field"
      :class="{'has-error': $v.customer.name.$error}"
    >
      <label
        class="label"

        for="customer_name"
      >
        <abbr title="necessário">*</abbr>
        {{ customerName }}
      </label>

      <div class="control">
        <input
          id="customer_name"
          v-model="customer.name"
          type="text"
          class="input is-medium"
          :class="{'is-danger': $v.customer.name.$error}"
          @blur="$v.customer.name.$touch()"
        >
      </div>

      <span
        v-if="!$v.customer.name.required"
        class="help is-danger"
      >
        Não pode ficar em branco.
      </span>
    </div>

    <div
      class="field"
      :class="{'has-error': $v.customer.cpf_cnpj.$error}"
    >
      <label
        class="label"
        for="customer_cpf_cnpj"
      >
        <abbr title="necessário">*</abbr>
        {{ customerCpfCnpj }}
      </label>

      <div class="control">
        <input
          id="customer_cpf_cnpj"
          v-model="customer.cpf_cnpj"
          type="text"
          class="input is-medium"
          placeholder="Apenas números, sem pontuações"
          :class="{'is-danger': $v.customer.cpf_cnpj.$error}"
          @blur="$v.customer.cpf_cnpj.$touch()"
        >
      </div>

      <span
        v-if="!$v.customer.cpf_cnpj.required"
        class="help is-danger"
      >
        Não pode ficar em branco.
      </span>
    </div>

    <div class="field">
      <label
        class="label"
        for="customer_simple_opt"
      >
        <abbr title="necessário">*</abbr>
        Optante pelo Simples Nacional
      </label>
      <div class="control">
        <span class="select is-medium is-fullwidth">
          <select
            id="customer_simple_opt"
            v-model="customer.simple_opt"
          >
            <option
              value="false"
              selected
            >Não</option>
            <option value="true">Sim</option>
          </select>
        </span>
      </div>
    </div>

    <div class="field">
      <label
        class="label"
        for="customer_state_taxpayer"
      >
        <abbr title="necessário">*</abbr>
        Indicador de Inscrição Estadual
      </label>
      <div class="control">
        <span class="select is-medium is-fullwidth">
          <select
            id="customer_state_taxpayer"
            v-model="customer.state_taxpayer"
          >
            <option value="non_payer">Não contribuinte</option>
            <option value="payer">Contribuinte</option>
            <option value="free">Contribuinte isento</option>
          </select>
        </span>
      </div>
    </div>

    <div class="field">
      <label
        class="label"
        for="customer_state_registration"
      >Inscrição Estadual</label>
      <div class="control">
        <input
          id="customer_state_registration"
          v-model="customer.state_registration"
          type="text"
          class="input is-medium"
          placeholder="Apenas números, sem pontuações"
        >
      </div>
    </div>

    <div class="field">
      <label
        class="label"
        for="customer_town_registration"
      >Inscrição Municipal</label>
      <div class="control">
        <input
          id="customer_town_registration"
          v-model="customer.town_registration"
          type="text"
          class="input is-medium"
          placeholder="Apenas números, sem pontuações"
        >
      </div>
    </div>

    <div
      class="field"
      :class="{'has-error': $v.customer.address_attributes.city_id.$error}"
    >
      <label
        class="label"
        for="customer_address_city"
      >
        <abbr title="necessário">*</abbr>
        Cidade
      </label>

      <div class="control">
        <span
          class="select is-medium is-fullwidth"
          :class="{'is-danger': $v.customer.address_attributes.city_id.$error}"
          @blur="$v.customer.address_attributes.city_id.$touch()"
        >
          <select
            id="customer_address_city"
            v-model="customer.address_attributes.city_id"
          >
            <option
              v-for="(city, index) in cities"
              :key="index"
              :value="city.id"
            >
              {{ city.name_with_state }}
            </option>
          </select>
        </span>
      </div>

      <span
        v-if="!$v.customer.address_attributes.city_id.required"
        class="help is-danger"
      >
        Não pode ficar em branco.
      </span>
    </div>

    <div
      class="field"
      :class="{'has-error': $v.customer.address_attributes.street.$error}"
    >
      <label
        class="label"
        for="customer_address_street"
      >
        <abbr title="necessário">*</abbr>
        Rua
      </label>

      <div class="control">
        <input
          id="customer_address_street"
          v-model="customer.address_attributes.street"
          type="text"
          class="input is-medium"
          placeholder="Apenas letras, sem pontuações"
          :class="{'is-danger': $v.customer.address_attributes.street.$error}"
          @blur="$v.customer.address_attributes.street.$touch()"
        >
      </div>

      <span
        v-if="!$v.customer.address_attributes.street.required"
        class="help is-danger"
      >
        Não pode ficar em branco.
      </span>
    </div>

    <div
      class="field"
      :class="{'has-error': $v.customer.address_attributes.number.$error}"
    >
      <label
        class="label"
        for="customer_address_number"
      >
        <abbr title="necessário">*</abbr>
        Número
      </label>

      <div class="control">
        <input
          id="customer_address_number"
          v-model="customer.address_attributes.number"
          type="text"
          class="input is-medium"
          placeholder="Apenas números, sem pontuações"
          :class="{'is-danger': $v.customer.address_attributes.number.$error}"
          @blur="$v.customer.address_attributes.number.$touch()"
        >
      </div>

      <span
        v-if="!$v.customer.address_attributes.number.required"
        class="help is-danger"
      >
        Não pode ficar em branco.
      </span>
    </div>

    <div
      class="field"
      :class="{'has-error': $v.customer.address_attributes.neighborhood.$error}"
    >
      <label
        class="label"
        for="customer_address_neighborhood"
      >
        <abbr title="necessário">*</abbr>
        Bairro
      </label>

      <div class="control">
        <input
          id="customer_address_neighborhood"
          v-model="customer.address_attributes.neighborhood"
          type="text"
          class="input is-medium"
          placeholder="Apenas letras, sem pontuações"
          :class="{'is-danger': $v.customer.address_attributes.neighborhood.$error}"
          @blur="$v.customer.address_attributes.neighborhood.$touch()"
        >
      </div>

      <span
        v-if="!$v.customer.address_attributes.neighborhood.required"
        class="help is-danger"
      >
        Não pode ficar em branco.
      </span>
    </div>

    <div
      class="field"
      :class="{'has-error': $v.customer.address_attributes.postal_code.$error}"
    >
      <label
        class="label"
        for="customer_address_postal_code"
      >
        <abbr title="necessário">*</abbr>
        CEP
      </label>

      <div class="control">
        <input
          id="customer_address_postal_code"
          v-model="customer.address_attributes.postal_code"
          v-imask="postal_code_mask"
          type="text"
          class="input is-medium"
          placeholder="00000-000"
          :class="{'is-danger': $v.customer.address_attributes.postal_code.$error}"
          @blur="$v.customer.address_attributes.postal_code.$touch()"
        >
      </div>

      <span
        v-if="!$v.customer.address_attributes.postal_code.required"
        class="help is-danger"
      >
        Não pode ficar em branco.
      </span>
    </div>
  </form>
</template>

<script>
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import { required } from 'vuelidate/lib/validators';
import { IMaskDirective } from 'vue-imask';
import { validationMixin } from 'vuelidate';

export default {
  directives: {
    imask: IMaskDirective
  },

  mixins: [
    validationMixin
  ],

  data() {
    return {
      postal_code_mask: {
        mask: '00000-000',
        lazy: false
      },
      customer: {
        person: 'legal_entity',
        name: '',
        cpf_cnpj: '',
        simple_opt: false,
        state_taxpayer: 'non_payer',
        state_registration: '',
        town_registration: '',
        address_attributes: {
          city_id: '',
          street: '',
          number: '',
          neighborhood: '',
          postal_code: '',
        }
      },

      cities: [],
    };
  },

  computed: {
    customerName: function () {
      if(this.customer.person === 'legal_entity') {
        return 'Razão Social';
      } else {
        return 'Nome';
      }
    },

    customerCpfCnpj: function () {
      if(this.customer.person === 'legal_entity') {
        return 'CNPJ';
      } else {
        return 'CPF';
      }
    }
  },

  mounted() {
    this.setCities();
  },

  validations() {
    return {
      customer: {
        name: { required },
        cpf_cnpj: { required },
        address_attributes: {
          city_id: { required },
          street: { required },
          number: { required },
          neighborhood: { required },
          postal_code: { required },
        }
      }
    };
  },

  methods: {
    setCities() {
      http.get('/api/cities')
        .then(getData)
        .then(data => this.cities = data)
        .catch(() => Promise.reject('FAIL_IN_FETCH_CITIES'));
    },

    handleSubmit() {
      if (this.$v.customer.$invalid) {
        this.$v.customer.$touch();
        return Promise.reject('FAIL_IN_CREATE_CUSTOMER');
      } else {
        return this.handleCreate();
      }
    },

    handleCreate() {
      const { customer } = this;
      return http.post('/api/customers', { customer })
        .then(getData)
        .then(data => Promise.resolve(data))
        .catch(() => Promise.reject('FAIL_IN_CREATE_CUSTOMER'));
    }
  }
};
</script>
