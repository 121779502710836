import { Controller } from 'stimulus';
import Moment from 'moment';
import Flatpickr from 'flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';

export default class extends Controller {
  static targets = [ 'filter', 'from', 'to' ]

  connect() {
    this.flatPickr = new Flatpickr(this.filterTarget, {
      mode: 'range',
      altInput: true,
      altFormat: 'd/m/Y',
      dateFormat: 'Y-m-d',
      defaultDate: this.defaultDate(),
      locale: Portuguese
    });
  }

  disconnect() {
    Turbolinks.clearCache();
    this.flatPickr.destroy();
  }

  submit() {
    Turbolinks.visit(`${window.location.pathname}?${this.byPeriod()}`);
  }

  defaultDate() {
    return [this.fromTarget.value, this.toTarget.value];
  }

  byPeriod() {
    const rangeDate = this.flatPickr.selectedDates.map(date => Moment(date).format('YYYY-MM-DD'));
    return `by_period[]=${rangeDate[0]}&by_period[]=${rangeDate[1]}`;
  }
}
