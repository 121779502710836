<template lang="html">
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações dos Produtos
    </h3>

    <div class="columns">
      <div class="column">
        <table class="table is-bordered">
          <thead>
            <tr>
              <th>* Produto</th>
              <th>* Quantidade</th>
              <th>* Unidade</th>
              <th>* CFOP</th>
              <th>* Valor</th>
              <th>Subtotal</th>
              <th style="border:none" />
            </tr>
          </thead>

          <tbody>
            <ProductItem
              v-for="(item, index) in nfe.items"
              :key="item.id || item.key"
              :item="item"
              :index="index"
              :products="products"
              :remove-item="removeItem"
              :add-item="addItem"
            />
          </tbody>
        </table>

        <a
          class="button"
          @click.prevent="addItem()"
        >
          + Nova Linha
        </a>

        <a
          class="button primary is-outlined"
          @click.prevent="openProductModal()"
        >
          + Novo Produto
        </a>

        <span class="eco-invoice-total">Total: {{ total | toCurrency }}</span>
      </div>
    </div>

    <Modal
      v-if="isProductVisible"
      title="Novo Produto"
      :is-visible="isProductVisible"
      :close-modal="closeProductModal"
      :save-button="addProduct"
    >
      <ProductForm ref="productForm" />
    </Modal>
  </div>
</template>

<script>
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import { toCurrency } from 'app/v1/src/utils/filters';
import Modal from 'app/v1/src/components/Modal';
import ProductForm from '../Product/New';
import ProductItem from './ProductItemCart';

export default {

  filters: {
    toCurrency
  },

  components: {
    Modal,
    ProductForm,
    ProductItem,
  },

  props: {
    nfe: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      products: [],
      isProductVisible: false,
      schema: {
        identifier: '',
        product_id: '',
        ncm: '',
        cest: '',
        cfop: '',
        name: '',
        unit: 'un',
        amount: 1,
        price: 0,
        selected: null,
      },
    };
  },

  computed: {
    total: function() {
      return this.nfe.items.reduce((prev, item) => {
        return prev + (parseFloat(item.price) * parseFloat(item.amount));
      }, 0);
    },
  },

  mounted() {
    this.getProducts().then(() => this.addItem());
  },

  methods: {
    getProducts() {
      return http.get('/api/products')
        .then(getData)
        .then(data => {
          this.products = data;
          return Promise.resolve();
        })
        .catch(() => Promise.reject('FAIL_IN_FETCH_PRODUCTS'));
    },

    addProduct() {
      this.$refs.productForm.handleSubmit()
        .then((data) => {
          this.products.push(data);
          this.closeProductModal();
        })
        .catch(() => Promise.reject('FAIL_ADD_PRODUCT'));
    },

    addItem() {
      this.nfe.items.push({
        ...this.schema,
        key: Math.round(+new Date() * Math.random())
      });
    },

    removeItem(index) {
      this.nfe.items =
        this.nfe.items.slice(0, index)
          .concat(this.nfe.items.slice(index+1, this.nfe.items.length));
    },

    openProductModal() {
      this.isProductVisible = true;
    },

    closeProductModal() {
      this.isProductVisible = false;
    },
  }
};
</script>
