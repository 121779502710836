<template lang="html">
  <form>
    <div
      class="field"
      :class="{'has-error': $v.product.name.$error}"
    >
      <label
        class="label"

        for="product_name"
      >
        <abbr title="necessário">*</abbr>
        Nome
      </label>

      <div class="control">
        <input
          id="product_name"
          v-model="product.name"
          type="text"
          class="input is-medium"
          :class="{'is-danger': $v.product.name.$error}"
          @blur="$v.product.name.$touch()"
        >
      </div>

      <span
        v-if="!$v.product.name.required"
        class="help is-danger"
      >
        Não pode ficar em branco.
      </span>
    </div>

    <div
      class="field"
      :class="{'has-error': $v.product.price.$error}"
    >
      <label
        class="label"
        for="product_price"
      >
        <abbr title="necessário">*</abbr>
        Preço
      </label>
      <div class="control">
        <CurrencyInput
          id="product_price"
          v-model="product.price"
          classes="input is-medium"
          :class="{'is-danger': $v.product.price.$error}"
          @blur="$v.product.price.$touch()"
        />
      </div>

      <span
        v-if="!$v.product.price.required"
        class="help is-danger"
      >
        Não pode ficar em branco.
      </span>
    </div>

    <div class="field">
      <label
        class="label"
        for="product_identifier"
      >Codigo do produto</label>
      <div class="control">
        <input
          id="product_identifier"
          v-model="product.identifier"
          type="text"
          class="input is-medium"
        >
      </div>
    </div>

    <div
      class="field"
      :class="{'has-error': $v.product.ncm.$error}"
    >
      <label
        class="label"
        for="product_ncm"
      >
        <abbr title="necessário">*</abbr>
        NCM
      </label>
      <div class="control">
        <input
          id="product_ncm"
          v-model="product.ncm"
          type="text"
          class="input is-medium"
          :class="{'is-danger': $v.product.ncm.$error}"
          @blur="$v.product.ncm.$touch()"
        >
      </div>

      <span
        v-if="!$v.product.ncm.required"
        class="help is-danger"
      >
        Não pode ficar em branco.
      </span>
    </div>

    <div class="field">
      <label
        class="label"
        for="product_cest"
      >CEST</label>
      <div class="control">
        <input
          id="product_cest"
          v-model="product.cest"
          type="text"
          class="input is-medium"
        >
      </div>
    </div>

    <div
      class="field"
      :class="{'has-error': $v.product.cfop.$error}"
    >
      <label
        class="label"
        for="product_cfop"
      >
        <abbr title="necessário">*</abbr>
        CFOP
      </label>
      <div class="control">
        <input
          id="product_cfop"
          v-model="product.cfop"
          type="text"
          class="input is-medium"
          :class="{'is-danger': $v.product.cfop.$error}"
          @blur="$v.product.cfop.$touch()"
        >
      </div>

      <span
        v-if="!$v.product.cfop.required"
        class="help is-danger"
      >
        Não pode ficar em branco.
      </span>
    </div>

    <div
      class="field"
      :class="{'has-error': $v.product.product_tax_id.$error}"
    >
      <label
        class="label"
        for="product_product_tax_id"
      >
        <abbr title="necessário">*</abbr>
        Tipo de ICMS
      </label>

      <div class="control">
        <span
          class="select is-medium is-fullwidth"
          :class="{'is-danger': $v.product.product_tax_id.$error}"
          @blur="$v.product.product_tax_id.$touch()"
        >
          <select
            id="product_product_tax_id"
            v-model="product.product_tax_id"
          >
            <option
              v-for="(tax, index) in taxes"
              :key="index"
              :value="tax.id"
            >
              {{ tax.name }}
            </option>
          </select>
        </span>
      </div>

      <span
        v-if="!$v.product.product_tax_id.required"
        class="help is-danger"
      >
        Não pode ficar em branco.
      </span>
    </div>
  </form>
</template>

<script>
import { http } from 'app/v1/src/plugins/http';
import { getData } from 'app/v1/src/utils/get';
import CurrencyInput from 'app/v1/src/components/CurrencyInput';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

export default {
  components: {
    CurrencyInput,
  },

  mixins: [
    validationMixin
  ],

  data() {
    return {
      taxes: [],
      product: {
        name: '',
        price: 0,
        identifier: '',
        ncm: '',
        cest: '',
        cfop: '',
        product_tax_id: ''
      },
    };
  },

  mounted() {
    this.setTaxes();
  },

  validations() {
    return {
      product: {
        name: { required },
        price: { required },
        ncm: { required },
        cfop: { required },
        product_tax_id: { required }
      },
    };
  },

  methods: {
    setTaxes() {
      http.get('/api/product_taxes')
        .then(getData)
        .then(data => this.taxes = data)
        .catch(() => Promise.reject('FAIL_IN_FETCH_TAXES'));
    },

    handleSubmit() {
      if (this.$v.product.$invalid) {
        this.$v.product.$touch();
        return Promise.reject('FAIL_IN_CREATE_PRODUCT');
      } else {
        return this.handleCreate();
      }
    },

    handleCreate() {
      const { product } = this;
      return http.post('/api/products', { product })
        .then(getData)
        .then(data => Promise.resolve(data))
        .catch(() => Promise.reject('FAIL_IN_CREATE_PRODUCT'));
    }
  }
};
</script>
