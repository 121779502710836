import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const person_type  = $('.js-person-type');

    const change_values_by_type = function(value) {
      if (value === 'individual') {
        $('.js-name-type').text('* Nome');
        return $('.js-cpf-cnpj-type').text('* CPF');
      } else {
        $('.js-name-type').text('* Razão Social');
        return $('.js-cpf-cnpj-type').text('* CNPJ');
      }
    };

    change_values_by_type(person_type.val());

    person_type.change(function() {
      return change_values_by_type($(this).val());
    });
  }
}
