<template lang="html">
  <CoolSelect
    ref="productSelect"
    v-model="productSelected.selected"
    item-text="name"
    :items="filterProducts"
    :input-el-custom-attributes="{ autofocus: true }"
    :disable-filtering-by-search="true"
    arrows-disable-instant-selection
    @select="onSelect"
    @search="onSearch"
  >
    <template slot="no-data">
      Não foi encontrado nenhum produto.
    </template>

    <template
      slot="item"
      slot-scope="{ item }"
    >
      <span class="item-name"> {{ item.name }} </span> <br>
      <span class="item-price"> {{ parseFloat(item.price) | toCurrency }} </span>
    </template>
  </CoolSelect>
</template>

<script>
import { CoolSelect } from 'vue-cool-select';
import * as JsSearch from 'js-search';
import { toCurrency } from 'app/v1/src/utils/filters';

export default {
  filters: {
    toCurrency
  },

  components: {
    CoolSelect
  },

  props: {
    products: {
      type: Array,
      required: true
    },

    productSelected: {
      type: Object,
      required: true
    },

    onSelect: {
      type: Function,
      required: true
    },
  },

  data() {
    return {
      search: null,
      searchTerm: null,
    };
  },

  computed: {
    filterProducts() {
      let filtered = this.products;

      if (this.searchTerm) {
        filtered = this.search.search(this.searchTerm);
      }

      return filtered;
    }
  },

  watch: {
    products: function() {
      this.setSearch();
    }
  },

  mounted() {
    this.setSearch();
    this.$refs.productSelect
      .$refs['IZ-select__input-for-text']
      .focus();
  },

  methods: {
    setSearch() {
      this.search = new JsSearch.Search('name');
      this.search.addIndex('identifier');
      this.search.addIndex('name');
      this.search.addIndex('barcode');
      this.search.addDocuments(this.products);
    },

    onSearch(term) {
      this.searchTerm = term;
    },
  },
};
</script>
