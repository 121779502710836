import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'content' ]

  connect() {
    if (this.autoPrint) { this.handlePrint(); }
  }

  // Public Methods
  print(event) {
    this.handlePrint();
    event.preventDefault();
  }

  // Private Methods
  handlePrint() {
    window.print();
  }

  // Getter Methods
  get autoPrint() {
    return this.data.get('auto-print');
  }
}
