<template lang="html">
  <input
    ref="input"
    type="string"
    :value="valueFormmatted"
    :disabled="disabled"
    :class="classes"
    @change="updateValue($event.target.value)"
  >
</template>

<script>
import { toNumber, toCurrency } from 'app/v1/src/utils/filters';

export default {
  props: {
    value: {
      type: Number,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classes: {
      type: String,
      default: 'input'
    }
  },

  computed: {
    valueFormmatted: function() {
      return toCurrency(parseFloat(this.value));
    }
  },

  methods: {
    updateValue(newValue) {
      this.$emit('input', toNumber(newValue));
    }
  }
};
</script>
