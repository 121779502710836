import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    var $navbarDropdown = Array.prototype.slice.call(document.querySelectorAll('.has-dropdown'), 0);
    var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    if ($navbarDropdown.length > 0) {
      $navbarDropdown.forEach(function ($el) {
        $el.addEventListener('click', function () {
          $el.classList.toggle('is-active');
        });
      });
    }

    if ($navbarBurgers.length > 0) {
      $navbarBurgers.forEach(function ($el) {
        $el.addEventListener('click', function () {
          var target = $el.dataset.target;
          var $target = document.getElementById(target);
          $el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
        });
      });
    }
  }
}
