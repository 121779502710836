<template lang="html">
  <div
    class="modal"
    :class="classModal"
  >
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="modal-card-title">
          {{ title }}
        </div>
        <button
          class="delete"
          @click="onClose"
        />
      </header>

      <section class="modal-card-body">
        <slot />
      </section>

      <footer
        v-if="showFooter"
        class="modal-card-foot"
      >
        <a
          class="button is-primary"
          @click="onSave"
        >
          {{ saveButtonText }}
        </a>
        <a
          class="button"
          @click="onClose"
        >Fechar</a>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Título da Modal'
    },

    isVisible: {
      type: Boolean,
      required: true
    },

    closeModal: {
      type: Function,
      required: true
    },

    saveButton: {
      type: Function
    },

    saveButtonText: {
      type: String,
      default: 'Salvar'
    },

    showFooter: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    classModal: function () {
      return {
        'is-active': this.isVisible
      };
    },
  },

  mounted: function () {
    document.addEventListener('keydown', (e) => {
      if (this.isVisible && e.keyCode == 27) {
        this.closeModal();
      }
    });
  },

  methods: {
    onSave() {
      this.saveButton();
    },

    onClose() {
      this.closeModal();
    }
  }
};
</script>
