import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    Iugu.setAccountID("DD49EAE9264744F38FE95A99B70EF8BE")
    Iugu.setTestMode(false)
    Iugu.setup()

    $('.js-payment-cc-brand').on('keyup', function(evt) {
      const brandClass = $('.creditcard-icon.brand');
      const number = $(this).val();

      brandClass.removeClass('visa');
      brandClass.removeClass('mastercard');
      brandClass.removeClass('amex');
      brandClass.removeClass('diners');

      const brand = Iugu.utils.getBrandByCreditCardNumber(number);
      if (brand) { $(brandClass).addClass(brand); }

      return true;
    });

    $('.js-payment-creditcard').submit(function(evt) {
      const form = this;
      const cc_token = $('.js-payment-cc-token');

      const tokenResponseHandler = function(data) {
        if(data.errors) {
          console.warn("[IUGU][ERROR]", JSON.stringify(data.errors));
        } else {
          cc_token.val(data.id);
        }

        return form.submit();
      };

      Iugu.createPaymentToken(this, tokenResponseHandler);
      return false;
    });
  }
}
