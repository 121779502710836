import { Controller } from 'stimulus';
import Handlebars from 'handlebars';

export default class extends Controller {
  connect() {
    const render = $('.js-render.js-subscription');
    const source = $('.js-template-success.js-subscription');
    const error = $('.js-template-error.js-subscription');

    if (render.length !== 0) {
      const success_template = Handlebars.compile(source.html());
      const error_template = Handlebars.compile(error.html());

      const url = source.attr('data-url');

      fetch(url)
        .then(response => render.html(success_template(response.data)))
        .catch(() => render.html(error_template));
    }
  }
}
