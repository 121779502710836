/* eslint no-console:0 */
// require.context('../images/', true, /\.(gif|jpg|png|svg)$/i);

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
// import Turbolinks from 'turbolinks';
// import Rails from 'rails-ujs';

import 'jquery';
import 'styles/v1';

const application = Application.start();
const context = require.context('app/v1/controllers', true, /\.js$/);

application.load(definitionsFromContext(context));

// Rails.start();
// Turbolinks.start();
