<template>
  <tr>
    <td>{{ installment.installment_number }}</td>
    <td>{{ installment.installment_date | formatDate }}</td>
    <td>{{ installment.installment_value | toCurrency }}</td>
    <td>
      <span
        v-if="isExpired"
        class="status-tag -expired"
      >
        Vencido
      </span>

      <span
        v-if="!isExpired"
        class="status-tag"
        :class="statusTagClass"
      >
        {{ $t(`paymentPaper.${installment.status}`) }}
      </span>
    </td>
    <td v-if="isPaid">
      <a
        class="button"
        @click="markUnpaid"
      >
        <span>
          Marcar como  <strong>Não Pago</strong>
        </span>
      </a>
    </td>
    <td v-if="!isPaid">
      <a
        class="button"
        @click="markPaid"
      >
        <span>
          Marcar como  <strong>Pago</strong>
        </span>
      </a>
    </td>
  </tr>
</template>

<script>
import { toCurrency, formatDate } from 'app/v1/src/utils/filters';

export default {
  filters: {
    toCurrency,
    formatDate
  },

  props: {
    installment: {
      type: Object,
      required: true
    },

    updateStatus: {
      type: Function,
      required: true
    }
  },

  computed: {
    isExpired: function() {
      return this.installment.expired && !this.isPaid;
    },

    isPaid: function() {
      return this.installment.status === 'paid';
    },

    statusTagClass: function() {
      return `-${this.installment.status}`;
    }
  },

  methods: {
    markPaid() {
      const status = {
        installment: {
          status: 'paid'
        }
      };

      this.updateStatus(this.installment.id, status);
    },

    markUnpaid() {
      const status = {
        installment: {
          status: 'unpaid'
        }
      };

      this.updateStatus(this.installment.id, status);
    }
  }
};
</script>

<style scoped>
</style>
