<template>
  <tr>
    <td>
      {{ installment.installment_number }} / {{ installmentNumbers }}
    </td>

    <td>
      {{ installment.installment_value | toCurrency }}
    </td>

    <td>
      <DateInput
        v-model="installment.installment_date"
        classes="input is-medium"
      />
    </td>
  </tr>
</template>

<script>
import { toCurrency } from 'app/v1/src/utils/filters';
import DateInput from 'app/v1/src/components/DateInput';

export default {
  filters: {
    toCurrency
  },

  components: {
    DateInput,
  },

  props: {
    installment: {
      type: Object,
      required: true
    },

    installmentNumbers: {
      type: Number,
      required: true
    }
  }
};
</script>

<style scoped>

</style>
