<template>
  <div class="content">
    <h3 class="eco-invoice-title">
      Informações Adicionais
    </h3>

    <div class="columns">
      <div class="column">
        <div class="field required">
          <div class="control">
            <label class="label">
              Observações
            </label>

            <textarea
              v-model="resource.description"
              class="textarea"
              placeholder="Informe como seu cliente poderá pagar, os descontos, as taxas de juros e multas ou descreva outras informações que possam ser relevantes para você e seu cliente."
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    resource: {
      type: Object,
      required: true
    },

    validator: {
      type: Object,
      required: true
    }
  }
};
</script>
