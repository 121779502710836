import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    setTimeout(() => $('.js-flash').fadeIn(), 100);

    if ($('.js-flash').length > 0) {
      setTimeout(() => $('.js-flash').fadeOut(), 5000);
    }

    $(document).click(() => $('.js-flash').fadeOut());
  }
}
