import moment from 'moment';
import numbro from 'numbro';
import ptBR   from 'numbro/languages/pt-BR';
import * as cpf from '@fnando/cpf';
import * as cnpj from '@fnando/cnpj';

numbro.registerLanguage(ptBR);
numbro.setLanguage('pt-BR');

export const toCurrency = (value) => {
  return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

export const toNumber = (value) => {
  return numbro(value).value();
};

export const toNumberFormatted = (value, format = '0.00') => {
  return numbro(value).format(format);
};



export const formatDateTime = (value) => {
  return moment(value).format('DD/MM/YYYY hh:mm', 'America/Sao_Paulo');
};

export const formatDate = (value) => {
  return moment(value).format('DD/MM/YYYY');
};

export const toDateTime = (value) => {
  return moment(value, 'DD/MM/YYYY hh:mm', true).format();
};

export const toDate = (value) => {
  return moment(value, 'DD/MM/YYYY', true).format();
};



export const formatCpfCnpj = (value) => {
  if (cpf.isValid(value)) {
    return cpf.format(value);
  } else if (cnpj.isValid(value)) {
    return cnpj.format(value);
  }
};



export const addDays = (days, date = new Date()) => {
  date.setDate(date.getDate() + days);
  return date;
};
