<template lang="html">
  <tr>
    <td>
      <div style="width:480px;">
        <ProductSearch
          :products="products"
          :product-selected="item"
          :on-select="onSelect"
        />
      </div>
    </td>

    <td width="120px">
      <NumberInput
        v-model="item.amount"
        :disabled="disabled"
        classes="input products-table-input"
      />
    </td>

    <td width="120px">
      <input
        v-model="item.unit"
        :disabled="disabled"
        type="string"
        class="input products-table-input"
      >
    </td>

    <td width="100px">
      <input
        v-model="item.cfop"
        :disabled="disabled"
        type="string"
        class="input products-table-input"
      >
    </td>

    <td width="200px">
      <CurrencyInput
        v-model="item.price"
        :disabled="disabled"
        classes="input products-table-input"
      />
    </td>

    <td width="200px">
      <span class="input products-table-input">
        {{ subtotal | toCurrency }}
      </span>
    </td>

    <td style="border:none">
      <a
        class="button"
        title="Remover Item"
        @click.prevent="removeItem(index)"
      >
        <i class="fa fa-times products-table-remove" />
      </a>
    </td>
  </tr>
</template>

<script>
import { isEmpty } from 'lodash';
import { toCurrency } from 'app/v1/src/utils/filters';
import ProductSearch from './ProductSearchCart';
import CurrencyInput from 'app/v1/src/components/CurrencyInput';
import NumberInput from 'app/v1/src/components/NumberInput';

export default {

  filters: {
    toCurrency
  },

  components: {
    CurrencyInput,
    NumberInput,
    ProductSearch,
  },
  props: {
    item: {
      type: Object,
      required: true
    },

    index: {
      type: Number,
      required: true
    },

    products: {
      type: Array,
      required: true
    },

    removeItem: {
      type: Function,
      required: true
    },

    addItem: {
      type: Function,
      required: true
    },
  },

  computed: {
    disabled: function() {
      return isEmpty(this.item.selected);
    },

    subtotal: function() {
      return parseFloat(this.item.price) * parseFloat(this.item.amount);
    },
  },

  methods: {
    onSelect() {
      const product = this.item.selected;

      if (product) {
        this.item.product_id = product.id;
        this.item.name       = product.name;
        this.item.ncm        = product.ncm;
        this.item.cest       = product.cest;
        this.item.cfop       = product.cfop;
        this.item.identifier = product.identifier;
        this.item.price      = product.price;

        this.addItem(this.index + 1);
      }
    },
  }
};
</script>
