import { Controller } from 'stimulus';
import Vue from 'vue/dist/vue.esm';
import App from 'app/v1/src/payment_paper/new.vue';

export default class extends Controller {
  connect() {
    const el = this.element;
    new Vue({
      el,
      render: h => h(App)
    });
  }
}
